<template>
  <div>
    <div style="background:#010b30">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>

    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1378px; margin:0 auto; text-align:left">
        <ul>
          <router-link
            :to="{ path: '/components/web/aboutus/corporateVision' }"
          >
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.qiyegaishu') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/aboutus/qyyj' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.qiyeyuanjing') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/aboutus/zyzz' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.zhuanyezizhi') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/joinus/case04' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:50px; padding-top:12px;  color: #ffffff;"
              >
                <div>04</div>
                <div>{{ $t('Nav.qiyewenhua') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">
            {{ $t('Nav.home') }}</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.abus')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="width:1200px; margin:0 auto; height:500px">
      <div style="float:left;width:500px; margin-top:150px;">
        <div
          style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
        >
          {{ $t('Nav.qiyegaishu') }}
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left; line-height:28px; "
        >
          {{ $t('Nav.qiyegaishuText01') }}
        </div>
      </div>
      <div style="float:right;margin-top:50px;">
        <el-image :src="qygsImg" style="width:640px; height:400px;"></el-image>
      </div>
    </div>
    <div style="width:1200px; margin:0 auto; height:500px;background:#F6F7F8;">
      <div style="float:left;margin-top:50px;">
        <el-image :src="qyyjImg" style="width:640px; height:400px;"></el-image>
      </div>
      <div style="float:right;width:500px; margin-top:150px;">
        <div
          style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
        >
          {{ $t('Nav.qiyeyuanjing') }}
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left;line-height:28px; "
        >
          {{ $t('Nav.qiyegaishuText02') }}
        </div>
      </div>
    </div>
    <div style="width:1200px; margin:0 auto; height:500px">
      <div style="float:left;width:500px; margin-top:150px;">
        <div
          style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
        >
          {{ $t('Nav.zhuanyezizhi') }}
        </div>
        <div
          style="text-indent:30px; margin-top:30px;text-align:left; line-height:28px;"
        >
          {{ $t('Nav.qiyegaishuText03') }}
        </div>
      </div>
      <div style="float:right;margin-top:50px;">
        <el-image :src="qyzzImg" style="width:640px; height:400px;"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'aboutus',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/22.jpg'),
      qygsImg: require('../../../assets/aboutus/abuoutus-img1.png'),
      qyyjImg: require('../../../assets/aboutus/abuoutus-img2.png'),
      qyzzImg: require('../../../assets/aboutus/abuoutus-img3.png'),
    }
  },
  mounted() {
    this.$bus.$emit('getCurNav', 'aboutus')
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 150px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
